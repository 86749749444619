(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/analytics-event/assets/javascripts/event.js') >= 0) return;  svs.modules.push('/components/components/analytics-event/assets/javascripts/event.js');

var svs = svs || {};

(function(svs, _) {
  'use strict';

  var logger = new svs.core.log.Logger('svs.analytics-event.Events');
  svs.components = svs.components || {};
  svs.components.analytics = svs.components.analytics || {};
  svs.core = svs.core || {};
  svs.core.analytics = svs.core.analytics || {};
  svs.core.analytics.adl = svs.core.analytics.adl || {};

  svs.components.analytics.trackEvent = function(evt, cb, context) {
    if (!Array.isArray(evt)) {
      evt = [evt];
    }
    var popevt;
    while (evt.length) {
      popevt = evt.shift();
      if (popevt.opt_value && isNaN(popevt.opt_value)) {
        popevt.opt_value = 0;
      } else if (!popevt.opt_value) {
        popevt.opt_value = 0;
      }

      var dataLayerData = {
        event: popevt.action,
        eventInfo: {
          eventName: popevt.opt_label,
          eventAction: popevt.action,
          eventPoints: popevt.opt_value,
          type: popevt.category,
          timeStamp: new Date(),
          effect: popevt.opt_label
        }
      };
      dataLayerPush(dataLayerData);
      var msg = new svs.core.log.Message(svs.core.log.MessageType.event);

      msg.eventAction = popevt.action;
      msg.eventLabel = popevt.opt_label;
      msg.eventCategory = popevt.category;
      msg.eventValue = popevt.opt_value;
      svs.core.log.track('svs.components.analytics', msg);

      if (popevt.action === 'Customer Registration') {
        window.adobeDataLayer.adlPush('reg', {
          site: svs.core.analytics.adl.site,
          consent: svs.core.analytics.adl.consent,
          user: svs.core.analytics.adl.user,
          page: svs.core.analytics.adl.page,
          category: popevt.category,
          action: popevt.action,
          label: popevt.opt_label,
          value: popevt.opt_value
        });
      } else {
        window.adobeDataLayer.adlPush('event', {
          site: svs.core.analytics.adl.site,
          consent: svs.core.analytics.adl.consent,
          user: svs.core.analytics.adl.user,
          page: svs.core.analytics.adl.page,
          category: popevt.category,
          action: popevt.action,
          label: popevt.opt_label,
          value: popevt.opt_value
        });
      }
    }

    if (typeof cb === 'function') {
      svs.core.log.flush();
      setTimeout(context ? function() {
        cb.call(context);
      } : cb, 200); 
    }
  };

  $(function() {
    try {
      if (svs.utils.cookie.read('analytics-events')) {
        var events = JSON.parse(decodeURIComponent(svs.utils.cookie.read('analytics-events')));

        _.each(events, function(event) {
          svs.components.analytics.trackEvent(event);
        });

        svs.utils.cookie.destroy('analytics-events');
      }
    } catch (ex) {
      logger.warn('Track serverside cookie failed.', ex + svs.utils.cookie.read('analytics-events'));
    }
  });
})(svs, _);


 })(window);